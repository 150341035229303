
import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";

@Component
export default class DisclaimerDialogComponent extends Vue {
  @Model("input", { type: Boolean }) readonly termsAccepted!: boolean;

  @Emit()
  input() {
    return this.localTermsAccepted;
  }

  @Watch("localTermsAccepted")
  onLocalTermsAcceptedChanged() {
    this.input();
  }

  @Watch("termsAccepted")
  onTermsAcceptedChanged() {
    if (JSON.stringify(this.localTermsAccepted) != JSON.stringify(this.termsAccepted)) {
      this.localTermsAccepted = this.termsAccepted;
    }
  }

  localTermsAccepted: boolean;

  constructor() {
    super();

    this.localTermsAccepted = this.termsAccepted;
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  acceptTerms() {
    this.$store.dispatch("profile/acceptTerms").then(() => {
      this.localTermsAccepted = false;
    });
  }
}
